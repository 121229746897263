<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.list_s !== 2 ? '审核报销单详情' : '查看报销单详情' }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="项目报销审核"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon">
        <el-form ref="form" :model="form" style="margin-bottom: 20px">
          <el-descriptions direction="vertical" :column="7" border style="width: 100%">
            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="单据编号"
              prop="expenseNumber"
              ref="expenseNumber"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName" ref="createName">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              label="部门"
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
            >
              <el-form-item prop="deptList" ref="deptList">
                {{ form.deptName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="归属人"
            >
              <el-form-item prop="ownerName" ref="ownerName">
                {{ form.ownerName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="领款人"
            >
              <el-form-item prop="payeeName" ref="payeeName">
                {{ form.payeeName }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="申请日期"
            >
              <el-form-item prop="taskDate" ref="taskDate">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="费用所属单位"
            >
              <el-form-item prop="companyType" ref="companyType">
                {{ form.companyType | dict(dictData.companyType) }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource projectLabelClassName"
              content-class-name="businessOutSource projectContentClassName"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">项目费用报销</span>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              :span="1"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="付款方式"
              :span="3"
            >
              <el-form-item prop="applyExpenseType" ref="applyExpenseType">
                <!-- <el-radio-group disabled v-model="form.applyExpenseType">
                  <el-radio label="2">正常付款申请</el-radio>
                  <el-radio label="1">冲还预付款</el-radio>
                </el-radio-group> -->
                {{
                  form.applyExpenseType == 'REGULAR_PAYMENT_REQUEST' ? '正常付款申请' : '冲还预付款'
                }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="projectLabelClassName"
              contentClassName="projectContentClassName"
              label="报销方式"
              :span="2"
            >
              <el-form-item prop="expenseMode" ref="expenseMode">
                {{ form.expenseMode | dict(dictData.expenseMode) }}
                <el-button
                  v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
                  size="medium"
                  type="warning"
                  style="margin-left: 10%; padding: 5px"
                  @click="registrationIsShowFn"
                  >原登记单</el-button
                >
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <!-- 经费预支单据 -->
        <el-table
          v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
          :data="form.counteractExpenseList"
          border
          style="margin-top: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column
            align="center"
            prop="expenseNumber"
            width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <div style="color: red">（包括审批中的金额）</div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="冲销金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- <div v-for="(i, k) in projectCostList" :key="k" v-show="activeName == 0"> -->
        <el-descriptions title="" :column="2" border v-show="activeName == 0">
          <el-descriptions-item
            label="项目名称"
            contentClassName="projectContentName"
            labelStyle="width:100px;"
          >
            <span style="display: inline-block; line-height: 40px; width: 100%">{{
              form.projectName
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item labelStyle="width:110px;">
            <template slot="label"> 项目承接单位 </template>
            <div style="line-height: 40px">
              {{ form.projectCompanyType | dict(dictData.companyType) }}
              <el-button
                style="float: right"
                type="success"
                v-if="form.status == 10 || form.status == 100"
                @click="exportFileZipFn"
                :loading="loading"
              >
                全部附件下载
              </el-button>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="height: 10px"></div>

        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table
            :header-cell-class-name="headerInvoiceClassName"
            :data="form.detailList"
            id="table"
          >
            <el-table-column label="序号" align="center" prop="index" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              :show-overflow-tooltip="false"
              label="日期"
              width="160"
            >
              <template slot-scope="scope">
                <el-date-picker disabled v-model="scope.row.taskDate" type="date"> </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              :show-overflow-tooltip="false"
              label="费用科目一"
              width="160"
            >
              <template slot="header" slot-scope="scope">
                费用科目一
                <div>
                  <el-button
                    size="mini"
                    type="warning"
                    :loading="typeDialogLoading"
                    @click="isDialogShow = true"
                    >科目说明</el-button
                  >
                </div>
              </template>
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" placeholder="" />
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              :show-overflow-tooltip="false"
              label="费用科目二"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeTwo"
                  :code="scope.row.typeOne"
                  placeholder=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="taskDate"
              :show-overflow-tooltip="false"
              label="费用科目三"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeThree"
                  :code="scope.row.typeTwo"
                  placeholder=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              :show-overflow-tooltip="false"
              label="金额 (元)"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              label="事由附件详细说明（含参与人员）"
              :show-overflow-tooltip="false"
              width="250"
            >
              <template slot-scope="scope">
                <span>
                  <el-input
                    :disabled="!form.isDisplayEditButton"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 9999 }"
                    v-model="scope.row.contentDetail"
                  ></el-input
                ></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  <el-date-picker
                    disabled
                    v-model="scope.row.estimatedRefundDate"
                    type="date"
                    value-format="timestamp"
                    placeholder="选择预计退回时间"
                  >
                  </el-date-picker>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="260"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                        <!--  <span style="color: red" v-else>编码：{{ n.invoiceNo }}</span> -->
                      </div>
                    </div></template
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="false"
              prop="expenseFileList"
              width="252"
              label="非发票附件上传"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div style="height: 20px"></div>

        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ form.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="form.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item></template
            >
            <el-descriptions-item
              label="本次冲销总金额"
              :span="form.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销支付金额"
              :span="form.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
        <div
          v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' && registrationShow"
          style="color: red"
        >
          请注意：当前总金额已超过原登记单总金额 10%,审批流程将会重新走报销流程
        </div>

        <div v-if="bidIsShow" style="margin: 36px 0">
          <div>
            <span style="color: red; font-size: 18px; margin-right: 5px">*</span>
            <span style="color: #000; font-size: 18px; margin: 5px 10px 0 0; font-weight: 700"
              >投标信息</span
            >
            <el-button
              type="success"
              v-if="![2, 3, 4].includes(options.list_s)"
              @click="bidDialog = true"
            >
              选择关联投标信息
            </el-button>
          </div>
          <el-table :data="bidTable" border max-height="300">
            <el-table-column
              align="center"
              prop="bidName"
              min-width="280"
              :show-overflow-tooltip="false"
              label="标的名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bidType"
              width="100"
              :show-overflow-tooltip="false"
              label="采购方式"
            >
              <template slot-scope="scope">
                {{ scope.row.bidType | dict(bidType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              width="70"
              :show-overflow-tooltip="false"
              prop="bidHostEscort"
              label="是否重点关注"
            >
              <template slot-scope="scope">
                {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="155"
              sortable
              prop="bidOpeningTime"
              label="开标时间"
            >
              <template slot-scope="scope">
                {{ scope.row.bidOpeningTime | timeFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="160"
              prop="bidOwnerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="bidAgency"
              label="代理\招标单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="160"
              :show-overflow-tooltip="false"
              prop="bidWinningAmount"
              label="中标金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.bidWinningAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="bidStatus"
              label="状态"
              width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.bidStatus | dict(bidStatus) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 20px"></div>
        <OpinionArea :procInstId="form.processInstId" :instInvolved="instInvolved"></OpinionArea>
      </div>
      <div class="fullScreenOperation">
        <el-button
          v-if="form.isDisplayEditButton"
          type="warning"
          :loading="loading"
          @click="tempSave"
        >
          编辑
        </el-button>
        <el-button
          v-if="options.list_s !== 2"
          type="success"
          :loading="loading"
          @click="showDialogForm(1)"
        >
          通过
        </el-button>
        <SelectDialog
          v-if="options.list_s !== 2 && form.status == 10 && permission(['FLOW_HAND_OVER'])"
          @selectDataChange="selectAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary" :loading="loading"> 转审 </el-button>
        </SelectDialog>

        <el-button
          v-if="options.list_s !== 2"
          type="danger"
          :loading="loading"
          @click="showDialogForm(2)"
        >
          不通过
        </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div slot="title" style="color: #409eff">
        {{ dg_form.status == 1 ? '审批通过意见' : '审批不通过意见' }}
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
      </div>
      <el-form :model="dg_form" :rules="dg_formRules" ref="dg_formRef">
        <el-form-item prop="" v-if="dg_form.status == 1">
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="opinion" v-else>
          <el-input
            @change="$forceUpdate()"
            v-model.trim="dg_form.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          :type="dg_form.status == 1 ? 'success' : 'danger'"
          style="width: 120px"
          @click="addApprove"
          >{{ dg_form.status == 1 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button
          type="warning"
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          plain
          @click="handleClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>

    <el-dialog :visible.sync="registrationIsShow" append-to-body width="80%">
      <div slot="title" style="color: #409eff">登记单</div>
      <LogisticsRegistration :form="oldRegistrationInfo"></LogisticsRegistration>
    </el-dialog>
    <!-- 费用科目说明 -->
    <TypeDialog :isDialogShow.sync="isDialogShow" @changeLoading="handleChangeLoading"></TypeDialog>

    <!-- 经费预支详情 -->
    <ExpenditureAdvanceDetails
      :isShow.sync="expenditureIsShow"
      :options="expenditureOptions"
      @closeExpenditure="expenditureIsShow = false"
    ></ExpenditureAdvanceDetails>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    LogisticsRegistration: () => import('@/views/publicView/LogisticsRegistration.vue'),
    TypeDialog: () => import('@/components/typeDialog.vue'),
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),

    amount() {
      let sum = 0
      if (this.form.detailList) {
        sum = this.form.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      sum = sum.toFixed(2)
      return sum
    },
    registrationShow() {
      let isShow = false
      let num = this.form.registrationExpenditureTotalCost * 1.1
      if (this.amount > num) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
    // 冲销中的金额
    writeOffMoney() {
      let num = 0
      num = this.totalCounteract.sumCounteractCost + this.thisTimeMoney
      return num
    },
    // 本次冲销总金额
    thisTimeMoney() {
      let num = 0
      if (this.form.counteractExpenseList) {
        this.form.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 报销支付金额
    paymentMony() {
      let num = 0
      num = this.amount - this.thisTimeMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    // 检查明细中发票附件是否已存在使用过
    isInvoiceUsed() {
      if (this.form.detailList) {
        return this.form.detailList.some(v =>
          v.expenseFileList.some(
            item => item.fileType === 'INVOICE' && item.fileInvoiceNoType === 'EXIST'
          )
        )
      }
    },
    bidIsShow() {
      if (this.form.detailList) {
        const item = this.form.detailList.find(
          v =>
            v.typeOne === 'zhao_tou_biao_fei_yong' ||
            v.typeTwo === 'tou_bao_zheng_jin' ||
            v.typeTwo === 'lv_yue_bao_zheng_jin'
        )
        if (item) {
          return true
        } else {
          return false
        }
      }

      return false
    },
    EstimatedShow() {
      if (this.form.detailList) {
        const item = this.form.detailList.find(
          v => v.typeTwo === 'tou_bao_zheng_jin' || v.typeTwo === 'lv_yue_bao_zheng_jin'
        )

        if (item) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      form: {},
      activeName: '0',
      type: 'portrait',
      projectCostList: [
        {
          id: '',
          detailList: [
            {
              taskDate: null,
              content: null,
              cost: 0,
              contentDetail: null,
            },
          ],
        },
      ],
      filepath: process.env.VUE_APP_FILEPATH,
      dialogFormVisible: false,
      dg_form: {
        opinion: '',
        status: 0,
      },
      dictData: {
        companyType: [],
        expenseMode: [],
      },
      dg_formRules: {
        opinion: [{ required: true, message: '请输入审批不通过意见', trigger: 'blur' }],
      },
      costType: '1',
      instInvolved: {}, //当前进度处理人数组
      dialogVisible: false,
      imgList: [],
      img_index: 0,
      oldRegistrationInfo: {},
      registrationIsShow: false,
      isDialogShow: false,
      typeDialogLoading: false,
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/科目数据.png',
      applyExpenseType: [], //付款方式字典
      expenditureOptions: {}, //预支详情
      expenditureIsShow: false, //预支详情弹窗
      totalCounteract: {
        sumAdvanceCost: 0, //经费预支总合计
        sumCounteractCost: 0, //	冲销中金额合计
      },
      bidTable: [], //投标信息
      bidStatus: [], //投标状态
      bidType: [],
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              scroll.scrollTop = 0
            }
          })
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.dg_form = {
          opinion: '',
          status: 0,
        }
        if (newVal) {
          this.init()
        }
      },
    },
  },
  created() {
    this.getCompanyType()
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    tempSave() {
      //todo 暂存
      this.loading = true
      //* 如果有id且当前状态是暂存，则走暂存编辑接口
      //todo 被删除的明细如何提交
      this.$api.projectCost
        .undeterminedEdit(this.form)
        .then(res => {
          this.loading = false
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log('错误：', err)
        })
    },
    headerInvoiceClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label === '发票附件上传') {
        return ['headerInvoiceClassName']
      }
      return []
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 经费预支-详情
    expenditureDetailFn(row) {
      this.expenditureOptions = { ...row, id: row.advanceId }
      this.expenditureIsShow = true
    },
    async exportFileZipFn() {
      let execute = false
      execute = this.form.detailList.some(v => v.expenseFileList.some(n => !!n))
      if (execute) {
        this.loading = true
        this.$api.common
          .exportFileZip({ documentId: this.form.id })
          .then(res => {
            const blob = new Blob([res], { type: 'application/zip' })
            const fileName = `项目报销&${this.form.expenseNumber}&${this.form.id}`
            // const fileName = this.form.id
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = window.URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              window.URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.loading = false
            this.$message.success('下载成功')
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.$message.warning('当前单据没有附件可下载')
      }
    },
    registrationIsShowFn() {
      if (this.form.registrationExpenditureId) {
        this.$api.register
          .selectDetailById(this.form.registrationExpenditureId)
          .then(res => {
            if (res.data) {
              this.oldRegistrationInfo = res.data
              this.registrationIsShow = true
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$message.warning('请选择登记单')
        this.registrationIsShow = false
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = this.imgList.findIndex(
        v =>
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
      )
      this.dialogVisible = true
    },
    init() {
      // this.form = {};
      this.detailList = []
      if (this.options.id) {
        this.loading = true
        this.$api.projectCost
          .selectDetailById(this.options.id)
          .then(res => {
            if (res.data) {
              if (res.data.bidManagement) {
                this.bidTable = [{ ...res.data.bidManagement }]
              }
              this.form = res.data
              this.totalCounteract.sumAdvanceCost = this.form.sumAdvanceCost
                ? this.form.sumAdvanceCost
                : 0
              this.totalCounteract.sumCounteractCost = this.form.sumCounteractCost
                ? this.form.sumCounteractCost
                : 0
              // this.form.applyExpenseType = 'REGULAR_PAYMENT_REQUEST' //临时前端写死
              // this.$refs.form.clearValidate()
              this.loading = false
              this.instInvolved = {
                instInvolvedUserName: res.data.instInvolvedUserName
                  ? res.data.instInvolvedUserName
                  : [],
                status: res.data.status,
              }
              this.projectPayedFileList = res.data?.affirmCostFileList
            }

            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    handleClose() {
      this.dialogFormVisible = false
      this.$refs.dg_formRef.resetFields()
      this.dg_form.opinion = ''
    },
    addApprove() {
      this.$refs.dg_formRef.validate(valid => {
        if (valid) {
          if (this.dg_form.status == 1) {
            if (this.dg_form.opinion == '') {
              this.dg_form.opinion = '通过'
            }
          }
          this.submit()
        }
      })
    },
    async submit() {
      //通过 this.dg_form.status 为1，不通过为2

      // const name = JSON.parse(localStorage.getItem("userInfo"));
      // this.dg_form.name = name.userName;

      // let arr = {
      //   auditUser: this.userInfo.staffId,
      //   status: this.dg_form.status == 1 ? 2 : 3,
      //   type: this.options.type,
      //   projectId: this.options.projectId,
      //   id: this.options.id,
      //   auditDate: new Date().getTime(),
      //   opinionRecordRequst: {
      //     isCheckPass: this.dg_form.status,
      //     opinion: this.dg_form.opinion,
      //     dateValue: new Date().getTime(),
      //   },
      // };
      if (this.dg_form.status == 1) {
        this.dg_form.processInstId = this.form.processInstId
        this.$api.addAuditor
          .disposeTask(this.dg_form)
          .then(res => {
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$api.projectCost
          .approvalReject(this.dg_form)
          .then(res => {
            this.dialogFormVisible = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    getCompanyType() {
      this.$api.dict.listSysDictData('COMPANY_TYPE', true).then(res => {
        this.dictData.companyType = res.data
      })
      this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
        this.dictData.expenseMode = res.data
      })
      this.$api.dict
        .listSysDictData('APPLY_EXPENSE_TYPE', true)
        .then(res => {
          this.applyExpenseType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_STATUS', true)
        .then(res => {
          this.bidStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_TYPE', true)
        .then(res => {
          this.bidType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    showDialogForm(status) {
      this.dg_form.status = status
      this.dg_form.id = this.form.id
      this.dialogFormVisible = true
    },

    handleChangeLoading(e) {
      this.typeDialogLoading = e
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';

/deep/.form-item-width {
  width: 30% !important;
}
/deep/.businessOutSource {
  //费用类别背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}
.addButton {
  margin-bottom: 10px;
}
.el-form-item {
  margin-bottom: 0px !important;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}

.color_0 {
  color: #606266;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.projectContentName {
  width: 50%;
}
/deep/.projectLabelClassName {
  width: 14.2%;
}
/deep/.projectContentClassName {
  height: 75px !important;
}
// 附件编码
.invoice-file {
  display: flex;
  margin-top: 5px;
  .invoice-file-left {
    width: 85%;
  }
}
/deep/.headerInvoiceClassName {
  background-color: #b2f3fe !important;
}
</style>
